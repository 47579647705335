<style>
.v-text-field input {
  font-size: 0.75em;
}
</style>
<template>
  <div>
    <v-card>
      <v-data-table
        :headers="_headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="busqueda"
        :options.sync="options"
        hide-default-footer
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn
            icon
            color="red"
            target="_blank"
            @click="$emit('abrirFormDae', item.FUE_ID)"
          >
            <v-icon color="#ff9800">mdi-table-edit</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.description="{ item }">
          <v-btn
            icon
            color="red"
            target="_blank"
            @click="abrirFormDae(item.FUE_ID)"
          >
            <v-icon color="#119800">mdi-square-edit-outline</v-icon>
          </v-btn>
          {{ item.FUE }}
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="_cp"
            :length="pageCount"
            @input="$emit('handlePageChange')"
            total-visible="10"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "ListaDaesFincaComponent",
  props: {
    fincaId: {
      type: String,
      default: null,
    },
    tipoEmpresa: {
      type: String,
      default: null,
    },
    lista: {
      type: Array,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    pageCount: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    busqueda: {
      type: String,
      default: null,
    },
    tipoEmpresa: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Finca", value: "FINCA_NOMBRE" },
      { text: "Codigo", value: "FUE_ID" },
      { text: "Nombre", value: "FUE" },
      { text: "Destino", value: "DAE_DEST" },
      { text: "Válido hasta", value: "FECHA_CADUCA" },
      { text: "Ingresado por", value: "INGRESADO_POR" },
      { text: "Fecha de ingreso", value: "FECHA_INGRESO" },
    ],

    //lista :[],
    paises: [],
    options: {},
    notifications: false,
    daeEdit: false,

    formDaeTitulo: "",
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months"]),

    ...mapGetters("access", []),
    numDae() {
      return (
        this.datosDae.daeNumAduana +
        "-" +
        this.datosDae.daeNumAnio +
        "-" +
        this.datosDae.daeNumRegimen +
        "-" +
        this.datosDae.daeNumSec
      );
    },
    _headers() {
      let head2 = [];
      for (let i = 0; i < this.headers.length; i++) {
        if (this.tipoEmpresa == "1") {
          if (
            ![
              "FUE_ID",
              "INGRESADO_POR",
              "FECHA_INGRESO",
              "FINCA_NOMBRE",
            ].includes(this.headers[i].value)
          ) {
            head2.push(this.headers[i]);
          }
        } else {
          head2.push(this.headers[i]);
        }
      }

      return head2;
    },
    _reglasMes() {
      if (this.datosDae.anio == new Date().getFullYear().toString()) {
        if (this.datosDae.mes < new Date().getMonth() + 1) {
          return [false || "El mes debe ser mayor o igual"];
        }
      }
    },

    _cp: {
      get: function () {
        return this.currentPage;
      },
      set: function (x) {
        this.$emit("update:currentPage", x);
      },
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
  },
  mounted() {
    //this.$vuetify.lang.current = 'es'
    //this.cargarLista()
    //this.$emit('cargarLista')
  },
  watch: {
    options: {
      handler() {
        //this.cargarLista()
        //this.$emit('cargarLista')
      },
      deep: true,
    },
  },
};
</script>
